;(function ($, window, document, undefined) {
    $(function () {

        // Allow "hover" on all bootstrap dropdown menus, this is
        // mostly useful for the navbar, but will work elsewhere too

        $.fn.unBindBootstrapNavbarCollapse = function() {

            return this.each(function() {

                var collapse = $(this).find('.navbar-collapse');

                var onMouseIn = function() {
                    $(this).addClass('open');
                };

                var onMouseOut = function() {
                    $(this).removeClass('open');
                };

                collapse.find('.dropdown').hover(onMouseIn, onMouseOut);

                collapse.on('hide.bs.collapse', function() {
                    $(this).find('.dropdown').bind('mouseenter', onMouseIn).bind('mouseleave', onMouseOut);
                }).on('show.bs.collapse', function() {
                    $(this).find('.dropdown').unbind('mouseenter').unbind('mouseleave');
                });

            });
        };

        $('.navbar').unBindBootstrapNavbarCollapse();

        // Add Lightbox support to images
        // That is, assuming it's included into the theme

        if (typeof lity != 'undefined') {
            $(document).on('click', '[href$=".jpg"], [href$=".png"]', lity);
        }



        /**
         * Gforms animated floating labels
         */
        $('.gfield').gfFloatingLabels();

        $('.gfield').trigger("focus");

        /**
         * Page Banner Slider
         * ------------------
         */
        $('.slides--slick').slick({
            dots: false,
            arrows: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 5000,
            fade: true,
            cssEase: 'linear'
        });


        /**
         * Testimonials Slider
         * --------------------
         */
        $('.testimonials__slider').slick({
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            // responsive: [{
            //     breakpoint: 1024,
            //     settings: {
            //         slidesToShow: 4,
            //         slidesToScroll: 4,
            //         infinite: true,
            //         dots: false
            //     }
            // }, {
            //     breakpoint: 768,
            //     settings: {
            //         slidesToShow: 3,
            //         slidesToScroll: 3
            //     }
            // }, {
            //     breakpoint: 480,
            //     settings: {
            //         slidesToShow: 2,
            //         slidesToScroll: 2
            //     }
            // }]
        });


        /**
         * Scroll to top
         * --------------------
         */
        $(".back-to-top").on('click', function(event) {
            if (this.hash !== "") {
              event.preventDefault();
              var hash = this.hash;

              $('html, body').animate({
                scrollTop: $(hash).offset().top
              }, 800, function(){

                window.location.hash = hash;
              });
            }
          });

    });
})(jQuery, window, document);