;(function($, window, document, undefined) {

    "use strict";

    var pluginName = "gfFloatingLabels",
        defaults = {
            className: 'gfield--focused'
        };

    function Plugin (element, options) {
        this.element = element;
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var self = this;
            $(document).bind('gform_post_render', function () {
                self.bindEvents();
            });
        },

        bindEvents: function () {
            var self = this;
            var $gField = $('.gfield');
            var $input = $gField.find('input, textarea');

            $input.bind('focus', function () {
                $(this).closest('.gfield').addClass(self.settings.className);
            });

            $input.bind('blur', function () {
                if ($(this).val() != '') {
                    return false;
                }

                $(this).closest('.gfield').removeClass(self.settings.className);
            });
        }

    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);